import select2 from 'select2'
window.select2 = select2();

$(document).ready(function() {
    if ($('body').hasClass('en')) {
        $('.select2').select2({
            placeholder: $(this).data('placeholder'),
        });
    } else {
        $('.select2').select2({
            placeholder: $(this).data('placeholder'),
            language: {
                noResults: function () {
                    return "Не найдено"
                }
            }
        });
    }
});
