import slick from 'slick-carousel';

window.slick = slick;

$(document).ready(function () {
	$('.slick').slick({
		variableWidth: true
	});

	$('.company__leaders-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	});

	$('.slick-plan').slick({
		variableWidth: false,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1035,
				settings: {
					slidesToShow: 2.3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1.8,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1.3,
				}
			},
		],
	});

	$('.slick-service').slick({
		variableWidth: false,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1035,
				settings: {
					slidesToShow: 2.3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1.8,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1.3,
				}
			},
		],
	});

	$('.slick-place').slick({
		variableWidth: true,
		infinite: true,
	});

	$('.slick-about').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 7000,
		arrows: false,
		dots: true,
		pauseOnHover: false,
		pauseOnFocus: false,
		adaptiveHeight: true,
	});

	$('.company__clients-slider').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: false,
		responsive: [
			{
				breakpoint: 1035,
				settings: {
					slidesToShow: 3.5,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
		],
	});

	$('.slick-autoplay').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
		dots: true,
		pauseOnHover: false,
		pauseOnFocus: false,

	});
});