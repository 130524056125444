const menuListener = function () {

	$('.footer__acc-title').off();

	if (window.matchMedia('(max-width: 991px)').matches) {
		$('.footer__acc-title').click(function (event) {
			$(this).toggleClass('footer__acc-title--active').next().slideToggle(300);
		});
	}
}

const wayListener = function () {

	$('.contacts__acc-title').off();

	if (window.matchMedia('(max-width: 767px)').matches) {
		$('.contacts__acc-title').click(function (event) {
			$(this).toggleClass('contacts__acc-title--active').next().slideToggle(300);
		});
	}
}

$(window).on('load', function () {
	$('#burgerButton').click(function () {
		// $('.header__burger-menu').toggleClass('header__burger-menu--show');
		$('.header__burger-menu').fadeToggle();
		$('.header__burger-icon').toggleClass('header__burger-icon--show');
		$('body').toggleClass('body--overflow-hidden');
	});

	menuListener();
	wayListener();

	$(window).resize(function () {
		menuListener();
		wayListener();
	});

	$('.marquee').liMarquee();

	$('.news__item p').text(function (i, text) {

		if (text.length >= 100) {
			text = text.substring(0, 100);
			const lastIndex = text.lastIndexOf(" "); //позиция последнего пробела
			text = text.substring(0, lastIndex) + '...'; //обрезаем до последнего слова
		}

		$(this).text(text);

	});

	const hideSearch = function () {
		$('#page__search').removeClass('show');
		$('.header__link-wrap').removeClass('hidden');
		$('.header__connect-link').removeClass('hidden');
		$('#searchButton').css('display', 'block');
		$('#searchSubmit').css('display', 'none');
	};

	$('#searchButton').click(function () {
		if ($('#page__search').hasClass('show')) {
			hideSearch();
		} else {
			$('#page__search').addClass('show');
			$('.header__link-wrap').addClass('hidden');
			$('.header__connect-link').addClass('hidden');
			$(this).css('display', 'none');
			$('#searchSubmit').css('display', 'block');
		}
	});

	$(document).on('click', function (e) {
		if (!e.target.classList.contains('header__search')
			&& !e.target.parentElement.classList.contains('header__search')) {
			hideSearch();
		}
	})

	function checkFlexGap() {
		// create flex container with row-gap set
		var flex = document.createElement("div");
		flex.style.display = "flex";
		flex.style.flexDirection = "column";
		flex.style.rowGap = "1px";

		// create two, elements inside it
		flex.appendChild(document.createElement("div"));
		flex.appendChild(document.createElement("div"));

		// append to the DOM (needed to obtain scrollHeight)
		document.body.appendChild(flex);
		var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
		flex.parentNode.removeChild(flex);

		return isSupported;
	}

	checkFlexGap();

	if (checkFlexGap()) {
		document.documentElement.classList.add("flexbox-gap");
	} else {
		document.documentElement.classList.add("no-flexbox-gap");
	}

	if (window.location.hash) {

		var href = window.location.hash;

		$('html, body').animate({
			scrollTop: $(href).offset().top - $('.header').height()
		}, 1500)
	}

});

$(document).ready(function () {
	$('.acc__title').click(function (event) {
		// if ($('.acc').hasClass('acc--one')) {
		//    $('.acc__title').not($(this)).removeClass('acc__title--active');
		//    $('.acc__content').not($(this).next()).slideUp(300);
		// } //если надо чтобы открывался только по одному блоку
		$(this).toggleClass('acc__title--active').next().slideToggle(300);
		$(this).toggleClass('header__burger-title--active');
	})

	// internship application
	$('#practiceFormButton').click(function () {
		if ($('.intern__application').css('display') == 'block') {
			$('.intern__application').css('display', 'none');
		} else {
			$('.intern__application').css('display', 'block');
		}
	})

	$('#closeFormButton').click(function () {
		$('.intern__application').css('display', 'none');
	})

	//dropdown

	// $('.header__link-dropdown').hover(
	//   function () {
	//     $(this).children('.header__link').addClass('header__link--active');
	//     $(this).children('.header__link-ripple').fadeIn(300);
	//   },
	//   function () {
	//     $(this).children('.header__link').removeClass('header__link--active');
	//     $(this).children('.header__link-ripple').fadeOut(300);
	//   }
	// );
	//
	const hideDropMenu = function () {
		$('.header__link').not($(this)).removeClass('header__link--active').next().slideUp(300);
	}

	$('.header__link').click(function () {

		if ($('.header__link-wrap').hasClass('one')) {
			$('.header__link').not($(this))
				.removeClass('header__link--active')
				.next().slideUp(300);
		}

		$(this).toggleClass('header__link--active').next().slideToggle(300);
	})

	$(document).on('click', function (e) {
		if (!e.target.classList.contains('header__link-dropdown')
			&& !e.target.parentElement.classList.contains('header__link-dropdown')) {
			hideDropMenu();
		}
	})

	//tabs

	if ($(".plan").length) {
		//tabs for index page (with sliders)

		$(".plan .tabs__content-item").first().addClass("active");
		$(".plan.tabs__wrapper .tabs__tab").click(function () {
			$(".plan.tabs__wrapper .tabs__tab").removeClass("active").eq($(this).index()).addClass("active");
			$(".plan .tabs__content-item").removeClass("active").eq($(this).index()).addClass("active")
		}).eq(0).addClass("active");
	} else {
		$(".tabs__content-item").not(":first").hide();
		$(".tabs__wrapper .tabs__tab").click(function () {
			$(".tabs__wrapper .tabs__tab").removeClass("active").eq($(this).index()).addClass("active");
			$(".tabs__content-item").hide().eq($(this).index()).fadeIn()
		}).eq(0).addClass("active");
	}

	// //tabs with scroll
	//
	// if (window.matchMedia('(max-width: 767px)').matches) {
	// 	if ($('.poryadok .menu__item') != null) {
	// 		$('.poryadok .menu__item').click(function () {
	// 			$('html, body').animate({
	// 				scrollTop: $('.poryadok .menu__content').offset().top
	// 			});
	// 		});
	// 	}
	// }

	//tabs for contacts

	if ($('.js-tabs__trigger') != null) {
		$('.js-tabs__trigger').click(function () {
			let id = $(this).attr('data-tab'),
				content = $('.js-tabs__content[data-tab="' + id + '"]');

			$('.js-tabs__content.active').removeClass('active');
			content.addClass('active');

			$('html, body').animate({
				scrollTop: $('.contacts__map-list').offset().top - $('.header').height()
			}, 1500)
		});
	}

	// faq

	$('.about__faq-icon-button').click(function () {
		$(this).toggleClass('active');
		$(this).parent('.about__faq-item').toggleClass('w-100');
	})

	//select lang

	$('.language-select').click(function () {
		$(this).toggleClass('open');
	});

	$('.language-select li').click(function () {
		// var setLang = $('.language-select').data('location'),
		// 	dataLangSelect = $(this).data('lang')
		// $('.language-select').data('location', dataLangSelect);
		$('.language-select li').removeClass('active');
		$(this).toggleClass('active');
	});

	//scroll to theme

	if ($('.page__nav .page__nav-link[href^="#"]').length) {
		var link = $('.page__nav .page__nav-link[href^="#"]');
		$(link).click(function (e) {

			var href = $(this).attr("href");

			e.preventDefault();

			$('html, body').animate({
				scrollTop: $(href).offset().top - $('.header').height()
			}, 1500)
		});
	}
});
